import isLookupCheckboxItems from "../Validations/LookupCheckboxObjValidator";
import isLookupListItems from "../Validations/LookupListObjValidator";
import isNaplans from "../Validations/NaplanObjValidator";
import {isNccdDisabilities, isDisabilityLookups, isNccdDisabilityCategories}  from "../Validations/NccdDisabilityObjValidator";
import isDisability from "../Validations/DisabilityObjValidator";
import isAboriginal from "../Validations/AboriginalObjValidator";
import isStudent from "../Validations/StudentObjValidator";

export const saveToLocalStorage = (key: string, value: any, expiryInMinutes: number) => {
    const now = new Date();

    const item = {
      value: value,
      expiry: now.getTime() + expiryInMinutes * 60000,
    };

    if (!isDisability(item.value) && !isAboriginal(item.value) && !isLookupListItems(item.value) && !isLookupCheckboxItems(item.value) && !isStudent(item.value) && !isNaplans(item.value) && !isNccdDisabilities(item.value) && !isDisabilityLookups(item.value) && !isNccdDisabilityCategories(item.value)) {
      return null;
    }
//    localStorage.setItem(key, JSON.stringify(item));
};
  

export const getFromLocalStorage = (key: string) => {
    const itemStr = localStorage.getItem(key);

    if (!itemStr) { return null; }

    const item = JSON.parse(itemStr);
    return null;
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }

    if (isDisability(item.value)){
      return item.value;
    } else if(isAboriginal(item.value)) {
      return item.value
    } else if(isLookupCheckboxItems(item.value)){
      return item.value
    } else if(isLookupListItems(item.value)){
      return item.value
    } else if(isStudent(item.value)){
      return item.value
    }else if(isNaplans(item.value)){
      return item.value
    }else if(isNccdDisabilities(item.value)){
      return item.value
    }else if(isDisabilityLookups(item.value)){
      return item.value
    }else if(isNccdDisabilityCategories(item.value)){
      return item.value
    }
    return null;
};