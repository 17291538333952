import { LookupListDisability, LookupListDisabilityCategory, NccdDisabilityLegacy } from "../Class/NccdDisabilityLegacy";

function isNccdDisabilityCategory(obj: any): obj is LookupListDisabilityCategory {
    return (
        obj &&
        typeof obj.categoryId === 'number' &&
        typeof obj.categoryName === 'string'
    );
}

function isNccdDisabilityCategories(items: any): items is LookupListDisabilityCategory[] {
    if(!Array.isArray(items)) return false;
    return items.every(isNccdDisabilityCategory);
}

function isNccdDisability(obj: any): obj is NccdDisabilityLegacy {
    return (
        obj &&
        typeof obj.disabilityOrder === 'boolean' &&
        typeof obj.imputedDescription === 'string' &&
        typeof obj.NccdDisability === 'string' &&
        typeof obj.nccdDisabilityId === 'string' &&
        typeof obj.otherDisabilityName === 'string' &&
        typeof obj.studentId === 'number' &&
        typeof obj.studentNccddisabilityId === 'number' &&
        typeof obj.studentPublicId === 'string'
    );
}

function isNccdDisabilities(items: any): items is NccdDisabilityLegacy[] {
    if(!Array.isArray(items)) return false;
    return items.every(isNccdDisability);
}

function isLookupListDisability(obj: any): obj is LookupListDisability {
    return (
        obj &&
        typeof obj.nccdDisabilityId === 'number' &&
        typeof obj.disabilityName === 'string' &&
        typeof obj.categoryId === 'number'
    );
}


function isDisabilityLookups(items: any): items is LookupListDisability[] {
    if(!Array.isArray(items)) return false;
    return items.every(isLookupListDisability);
}

export {isNccdDisabilities, isDisabilityLookups, isNccdDisabilityCategories};