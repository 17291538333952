import React, { useEffect, useState } from 'react';
import {  Disability } from "../Class/StudentPlan"
import { useStudentPlan } from '../Context/StudentPlanContext';
import { DisabilitySchema, handleYupError } from '../Validations/DisabilitySchema';
import * as Yup from 'yup';
import { FormSubmitContext } from '../Context/FormSubmitContext';
import { useUpdatePlanDetails } from '../Hooks/useUpdatePlanDetails';
import SectionRef from '../Enums/SectionRef';

interface FormSubmitProviderProps {
    children: React.ReactNode;
}

export const FormSubmitProvider: React.FC<FormSubmitProviderProps> = ({children}) => {
    const { studentPlanData, student, studentPublicId, setStudentPlanData } = useStudentPlan();
    const {updateDisabilityPlan} = useUpdatePlanDetails();
    const [initialFormData, setInitialFormData] = useState<Disability>(studentPlanData.disability);
    const [formData, setFormData] = useState<Disability>(studentPlanData.disability);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [errors, setErrors] = useState<{ [key: string]: string | string[] }>({});
    const [hasInitialized, setHasInitialized] = useState<boolean>(false);

    useEffect(() => {
        if (!hasInitialized) {
            setInitialFormData(studentPlanData.disability);
            setFormData(studentPlanData.disability);
        }
    }, [studentPlanData.disability, hasInitialized] );

    const isValidString = (input: string | null | undefined): boolean => {
        return input != null && input.trim() !== '';
    }

    const isSectionDataExists = (formData: Disability, sectionType: SectionRef, excludeDeliveryAdj?: boolean): boolean => {
        switch(sectionType){
            case SectionRef.Curriculum:
                return formData.curriculumAssessmentSections.syllabusOutcomes.length > 0 ||
                    isValidString(formData.curriculumAssessmentSections.syllabusFurtherDetails) ||
                    formData.curriculumAssessmentSections.interventions.length > 0 ||
                    formData.curriculumAssessmentSections.alternateMaterials.length > 0 ||
                    formData.curriculumAssessmentSections.tasks.length > 0 ||
                    formData.curriculumAssessmentSections.explicitTeachings.length > 0 ||
                    formData.curriculumAssessmentSections.visualSupports.length > 0 ||
                    formData.curriculumAssessmentSections.assistiveTechnologies.length > 0 ||
                    formData.curriculumAssessmentSections.brailleLowVisions.length > 0 ||
                    formData.curriculumAssessmentSections.assessmentAdjustmentsSections.assessmentAdjustments.length > 0 ||
                    isValidString(formData.curriculumAssessmentSections.assessmentAdjustmentsSections.specificAdjustmentsIntervention) ||
                    isValidString(formData.curriculumAssessmentSections.assessmentAdjustmentsSections.specificAdjustmentsDisability) ||
                    isValidString(formData.curriculumAssessmentSections.assessmentAdjustmentsSections.specialistSoftware) ||
                    (!excludeDeliveryAdj && formData.curriculumAssessmentSections.deliveryAdjustments.some(delivery => !delivery.isArchived))
            case SectionRef.Communication:
                return formData.communicationSections.communications.length > 0 ||
                    isValidString(formData.communicationSections.furtherInformationCommunication) ||
                    (!excludeDeliveryAdj && formData.communicationSections.deliveryAdjustments.some(delivery => !delivery.isArchived));
            case SectionRef.Environment:
                return formData.environmentalSections.environmentals.length > 0 ||
                    isValidString(formData.environmentalSections.furtherInformationEnvironmental) ||
                    isValidString(formData.environmentalSections.furtherInformationSpecialistEquipment) ||
                    (!excludeDeliveryAdj && formData.environmentalSections.deliveryAdjustments.some(delivery => !delivery.isArchived));
            case SectionRef.Movement:
                return formData.movementSections.movements.length > 0 ||
                    formData.movementSections.movementPlannings.length > 0 ||
                    isValidString(formData.movementSections.furtherInformationMovement) ||
                    (!excludeDeliveryAdj && formData.movementSections.deliveryAdjustments.some(delivery => !delivery.isArchived));
            case SectionRef.Personal:
                return formData.personalCareSections.personalCares.length > 0 ||
                    formData.personalCareSections.personalCarePlannings.length > 0 ||
                    isValidString(formData.personalCareSections.furtherInformationPersonalCare) ||
                    (!excludeDeliveryAdj && formData.personalCareSections.deliveryAdjustments.some(delivery => !delivery.isArchived));
            case SectionRef.Safety:
                return formData.safetySections.safeties.length > 0 ||
                    formData.safetySections.safetyPlannings.length > 0 ||
                    isValidString(formData.safetySections.furtherInformationSafety) ||
                    (!excludeDeliveryAdj && formData.safetySections.deliveryAdjustments.some(delivery => !delivery.isArchived));
            case SectionRef.Social:
                return formData.socialEmotionalSections.socialEmotionals.length > 0 ||
                    formData.socialEmotionalSections.socialEmotionalPlannings.length > 0 ||
                    isValidString(formData.socialEmotionalSections.furtherInformationSocialEmotional) || 
                    (!excludeDeliveryAdj && formData.socialEmotionalSections.deliveryAdjustments.some(delivery => !delivery.isArchived));
            default: 
                return false; 
        }
    }

    const handleSubmit = async () => {
        setIsSubmitting(true);

        try {
            await DisabilitySchema.validate(formData, {abortEarly: false});
            setErrors({});
            const planUpdate: Disability = {
                ...studentPlanData.disability, 
                studentStrengths: formData.studentStrengths, 
                functionalImpactSections: formData.functionalImpactSections,
                curriculumAssessmentSections: { ...formData.curriculumAssessmentSections, isDataExists: isSectionDataExists(formData, SectionRef.Curriculum), deliveryAdjustments: studentPlanData.disability.curriculumAssessmentSections.deliveryAdjustments},
                communicationSections: {...formData.communicationSections, isDataExists: isSectionDataExists(formData, SectionRef.Communication), deliveryAdjustments: studentPlanData.disability.communicationSections.deliveryAdjustments},
                environmentalSections: {...formData.environmentalSections, isDataExists: isSectionDataExists(formData, SectionRef.Environment), deliveryAdjustments: studentPlanData.disability.environmentalSections.deliveryAdjustments},
                movementSections: {...formData.movementSections, isDataExists: isSectionDataExists(formData, SectionRef.Movement), deliveryAdjustments: studentPlanData.disability.movementSections.deliveryAdjustments},
                personalCareSections: {...formData.personalCareSections, isDataExists: isSectionDataExists(formData, SectionRef.Personal), deliveryAdjustments: studentPlanData.disability.personalCareSections.deliveryAdjustments},
                safetySections: {...formData.safetySections, isDataExists: isSectionDataExists(formData, SectionRef.Safety), deliveryAdjustments: studentPlanData.disability.safetySections.deliveryAdjustments},
                socialEmotionalSections: {...formData.socialEmotionalSections, isDataExists: isSectionDataExists(formData, SectionRef.Social),  deliveryAdjustments: studentPlanData.disability.socialEmotionalSections.deliveryAdjustments},
                evidenceMonitoringSections: formData.evidenceMonitoringSections,
            };

            await updateDisabilityPlan(studentPublicId, student.studentId, planUpdate, setStudentPlanData, "Plan updated successfully!");
            setInitialFormData(planUpdate);
            setFormData(planUpdate);
        } catch (err) {
            if (err instanceof Yup.ValidationError) { 
                setErrors(handleYupError(err)); 
            }
        } finally{ 
            setIsSubmitting(false); 
        }
    };

    return (
        <FormSubmitContext.Provider value={{formData, setFormData, initialFormData, handleSubmit, isSubmitting, setIsSubmitting, errors, setErrors, setHasInitialized, isSectionDataExists }}>
            {children}
        </FormSubmitContext.Provider>
    );
};
